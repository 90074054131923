<template>
    <b-row>
        <b-col cols="12">
            <b-card title="">
                <b-overlay
                    variant="white"
                    :show="showLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <div class="ml-1 mb-2">
                        <b-row>
                            <b-col cols="12" md="9"
                                   class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                            >
                                <button class="btn btn-primary" @click="exportToExcel">Exportar a Excel</button>
                            </b-col>
                        </b-row>
                    </div>

                    <b-table
                        ref="refUserListTable"
                        class="position-relative table-size-list"
                        :items="maintenance_list"
                        responsive
                        striped
                        sticky-header
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="No hay registros"
                    >

                        <template #cell(periodicity)="data">
                            {{ maintenancePeriods.find(element => element.value == data.item.periodicity) ? maintenancePeriods.find(element => element.value == data.item.periodicity).name : ''  }}
                        </template>

                        <template #cell(year)="data">
                            {{ new Date(data.item.expected_date).getFullYear() }}
                        </template>

                        <template #cell(expected_date)="data">
                            {{ helpers.formatDate(data.item.expected_date) }}
                        </template>

                        <template #cell(effective_date)="data">
                            {{ data.item.effective_date ? helpers.formatDate(data.item.effective_date) : '' }}
                        </template>

                        <template #cell(segment_id)="data">
                            {{ data.item.master_installation.segment ? data.item.master_installation.segment.segment : null }}
                        </template>

                        <template #cell(document_type_id)="data">
                            {{ data.item.document_types ? data.item.document_types.type : null }}
                        </template>

                        <template #cell(installation_code)="data">
                            {{ data.item.master_installation.installation_code }}
                        </template>

                        <template #cell(project_responsible_id)="data">
                            {{ data.item.providers ? data.item.providers.provider : null }}
                        </template>

                        <template #cell(client_email)="data">
                            {{ data.item.master_installation.client_email }}
                        </template>

                        <template #cell(business_model)="data">
                            {{ data.item.master_installation.business_model }}
                        </template>

                        <template #cell(postal_code)="data">
                            {{ data.item.master_installation.postal_code }}
                        </template>

                        <template #cell(postal_code)="data">
                            {{ data.item.master_installation.postal_code }}
                        </template>

                        <template #cell(province)="data">
                            {{ data.item.master_installation.province_country ? data.item.master_installation.province_country.province : null }}
                        </template>

                        <template #cell(city)="data">
                            {{ data.item.master_installation.city ? data.item.master_installation.city : null }}
                        </template>


                    </b-table>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {BRow, BCol, BTable, BCard, BOverlay, BBadge} from 'bootstrap-vue'
import {mapState} from "vuex"
import helpers from "@/resources/helpers/helpers"
import ExcelJS from "exceljs"
import {maintenancePeriods} from "@/constants/childrenDocuments"

export default {
    name: "ListView",
    components: {
        BRow,
        BCol,
        BTable,
        BCard,
        BOverlay,
    },
    data() {
        return {
            columns: [
                {key: 'year', label: 'Año', sortable: false},
                {key: 'expected_date', label: 'Fecha Prevista', sortable: false},
                {key: 'effective_date', label: 'Fecha Realización', sortable: false},
                {key: 'segment_id', label: 'Segmento', sortable: false},
                {key: 'document_type_id', label: 'Descripción tarea', sortable: false},
                {key: 'periodicity', label: 'Periodicidad', sortable: false},
                {key: 'installation_code', label: 'CodInstalacionMaster', sortable: false},
                {key: 'project_responsible_id', label: 'Proveedor', sortable: false},
                {key: 'client_email', label: 'Email', sortable: false},
                {key: 'business_model', label: 'Modelo De Negocio', sortable: false},
                {key: 'postal_code', label: 'CP', sortable: false},
                {key: 'province', label: 'Provincia', sortable: false},
                {key: 'city', label: 'Poblacion', sortable: false},
            ],
            showLoading: false,
            helpers,

            figures: [
                {'id': 1, 'name': 'MANTENEDOR'},
                {'id': 2, 'name': 'PROPIETARIO'},
                {'id': 3, 'name': 'TITULAR'}
            ],

            maintenancePeriods
        }
    },
    computed: {
        ...mapState('dashboardMetricsVhe', ['maintenance_list']),
    },
    created() {
        this.getMaintenancePlanned()
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        formatDate(date) {
            const dateFormated = new Date(date)

            return dateFormated.toLocaleDateString("es-ES")
        },
        getMaintenancePlanned() {
            this.showLoading = true

            this.$store.dispatch('dashboardMetricsVhe/getMaintenanceMetrics', {
                relations: ['masterInstallation', 'providers', 'documentTypes',
                    'masterInstallation.provinceCountry', 'masterInstallation.projectResponsible',
                    'masterInstallation.segment'],
            })
                .then(response => {
                    this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                    console.log(error)
                })
        },
        async exportToExcel() {
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('Mantenimientos')

            const headers = [
                'Año',
                'Fecha Prevista',
                'Fecha Realización',
                'Segmento',
                'Descripción tarea',
                'Periodicidad',
                'CodInstalacionMaster',
                'Proveedor',
                'Email',
                'Modelo De Negocio',
                'CP',
                'Provincia',
                'Poblacion',
            ]

            worksheet.addRow(headers)

            this.maintenance_list.forEach(detail => {
                worksheet.addRow([
                    new Date(detail.expected_date).getFullYear(),
                    helpers.formatDate(detail.expected_date),
                    detail.effective_date ? helpers.formatDate(detail.effective_date) : '',
                    detail.master_installation.segment ? detail.master_installation.segment.segment : null,
                    detail.document_types ? detail.document_types.type : null,
                    maintenancePeriods.find(element => element.value == detail.periodicity) ? maintenancePeriods.find(element => element.value == detail.periodicity).name : '',
                    detail.master_installation.installation_code,
                    detail.providers ? detail.providers.provider : null,
                    detail.master_installation.client_email,
                    detail.master_installation.business_model,
                    detail.master_installation.postal_code,
                    detail.master_installation.province_country ? detail.master_installation.province_country.province : null,
                    detail.master_installation.city ? detail.master_installation.city : null,
                ])
            })

            worksheet.columns.forEach(column => {
                const lengths = column.values.map(v => v.toString().length)
                const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'))
                column.width = maxLength
            })

            // Generar el archivo Excel
            const buffer = await workbook.xlsx.writeBuffer()

            // Crear un Blob para la descarga
            const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})

            // Crear una URL para el Blob
            const url = window.URL.createObjectURL(blob)

            // Crear un enlace de descarga y hacer clic en él
            const a = document.createElement('a')
            a.href = url
            a.download = 'mantenimientosVHE.xlsx'
            a.click()

            // Liberar recursos
            window.URL.revokeObjectURL(url)
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>